// ----------------------------------------------------------------------
// Layout Breakpoints
// ----------------------------------------------------------------------
$maxWidth: 1024px;
$myHomeCardMaxWidth: 560px;
$headerMaxWidth: 100%;
$headerMaxHeight: 100%;
$halfMaxWidth: 50%;
$halfMaxHeight: 50%;
$oneThirdUnit: 33.3%;
$sectionsHorizontalPadding: 90px;
$sectionsVerticalPadding: 70px;
$sizeZero: 0;

// ----------------------------------------------------------------------
// Font Sizes
// ----------------------------------------------------------------------

//Style guide

$regularSize: 13px;
$regularBoldSize: 14px;
$regularLineHeight: 22px;
$smallSize: 12px;
$extraSmallSize: 11px; // ravi wanted it
$bigFont: 22px;
$biggerFont: 32px;
$regularHeader: 16px;
$bigHeader: 28px;
$XLFont: 40px;
$XXLargeFont: 80px;
$regularLineHeight: 1.5;
$smallestSize: 8px;
$newInputSize: 14px;
$labelSizeSmall: 10px;
$errorTextSizeSmall: 9px;
$XXLargeFont: 80px;
$regularButton: 18px;
$bannerSize: 105px;
$primaryHeadingSize: 55px;
$secondaryHeadingSize: 35px;
$extraSmallFort: 8px;

$mobileBannerSize: 42px;
$mobileHeadingSize: 13px;
$mobileSubBannerSize: 22px;
$mobileBigFont: 12px;
$mobileButtonFontSize: 14px;

$headingSize: 40px;
$extraSmallFort: 8px;

$bigHeader: 28px;
$XXLargeFont: 80px;
// ----------------------------------------------------------------------
// Media query
// ----------------------------------------------------------------------
$mobileHorizontalPadding: 20px;
$mobileVerticalPadding: 30px;

// ----------------------------------------------------------------------
// Font Families
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Grid
// ----------------------------------------------------------------------
$grid-columns: 12;
$homeSpriteWidth: 648px;

//-----------------------------------------------------------------------
// Spacing
//-----------------------------------------------------------------------

$padding-smallest: 2px;
$padding-vertical-xs: 4px !default;
$padding-horizontal-xs: 8px !default;

$padding-vertical-sm: 8px !default;
$padding-horizontal-sm: 16px !default;

$padding-vertical-md: 16px !default;
$padding-horizontal-md: 32px !default;

$padding-vertical-lg: 40px !default;
$padding-horizontal-lg: 48px !default;

$padding-vertical-xl: 80px !default;
$padding-horizontal-xl: 64px !default;

$checkbox-height: 16px;
$checkbox-width: 16px;

$base-spacing-unit: 16px;
$half-spacing-unit: $base-spacing-unit/2;
$quarter-spacing-unit: $base-spacing-unit/4;
$double-spacing-unit: $base-spacing-unit * 2;
$one-half-spacing-unit: $base-spacing-unit * 3/2;

//--------------------------------
// Assure units
//--------------------------------
$logoWidth: 180px;
$mobileLogoWidth: 72px;

$iconWidth: 155px;
$iconHeight: 308px;
$mobileIconWidth: 51px;
$mobileIconHeight: 56px;

$hrWidth: 1px;
$mobileBorderWidth: 1px;
$mobileHrWidth: 0.5px;
$headingWidth: 248px;

$brandOfferLogoHeight: 80px;
$brandOfferRowWidth: 80%;

$smallBadgeHeight: 20px;
$smallBadgeWidth: 100px;

$circularImageWidth: 50px;
$mobileCircularImageWidth: 32px;
