body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: NeurialGrotesk-Medium;
  font-display: auto;
  src: url(/assets/fonts/NeurialGrotesk-Medium.ttf);
}

@font-face {
  font-family: NeurialGrotesk-Regular;
  font-display: auto;
  src: url(/assets/fonts/NeurialGrotesk-Regular.ttf);
}
