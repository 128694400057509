@import "./globals";
@import "./reset";
@import "./responsive";
@import "./house";
// Text aligns
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.TopArrow {
  display: none;
}

//paragraph
.para {
  font-size: 13px;
  line-height: $regularLineHeight;
}

.cardShadow {
  border: 1px solid $grey4;
  transition: all 0.2s ease-in-out 0s;

  &:hover {
    transform: translateY(-0.1em);
    border-color: $black;
  }

  &:active {
    transform: translateY(0);
    border-color: $black;
  }
}

//Position
.relative {
  position: relative;
}

.marginNone {
  margin: 0;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.static {
  position: static;
}

.rupee {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.flyingSection {
  position: relative;
  z-index: 11;
  border-top: 1px solid $grey4;
}

.HorizontalScroll {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.customScroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.8);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
  }
}

.hideScroll {
  &.removeHeight {
    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.greyScale {
  filter: grayscale(1);
}

.hide {
  display: none;
}

.viewLink {
  font-size: 13px;
  color: $secondary;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $grey2;
  }
}

.mobileTopShadow {
  @include mobile {
    box-shadow: 0 2px 8px $grey2;
  }
}

.DotLoaderCenter {
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;

  .DotLoader {
    display: inline-block;
  }
}

.checkmark {
  display: inline-block;

  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid $secondary;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: $alert;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.smoothScroll {
  -webkit-overflow-scrolling: touch;
}

.flipHorizontal {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.closeHover {
  transition: all ease 0.3s;
  border-radius: 0;

  &:hover {
    border-radius: 50%;
    background-color: $grey5;
  }
}

.subtext {
  font-size: $regularSize;
  line-height: $regularLineHeight;
  color: $grey2;

  &.subheader {
    color: $grey1;
  }
}

.smalltext {
  font-size: $smallSize;
  line-height: 1.5;
  color: $grey2;
}

.headingText {
  font-size: $bigFont;
  line-height: $regularLineHeight;
  color: $grey1;
}

.fullscreenWrapper {
  max-width: $maxWidth;
  margin: auto;
  position: relative;

  @include mobile {
    max-width: none;
    width: 100%;
    box-sizing: border-box;
    padding-left: $mobileHorizontalPadding;

    &.noPadding {
      padding-left: 0;
    }
  }
}

.halfScreen {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.hr {
  width: 90%;
  margin: auto;
  border-top: 1px solid $grey7;
}


.introBlock {
  padding: 20px;
  width: 70%;
  margin: auto;
  font-size: 16px;
  p{
      margin-bottom: 20px;
  }
  img {
      width: 100%;
      margin-bottom: 20px;
  }
  @include mobile {
    width: 100%;
  }
}