@import "~scss/base/globals";
$houseMargin: 0.01; // ratio to 1
.houseCardWrapper {
  position: relative;
  vertical-align: top;
  padding-bottom: 20px;

  .cardShadow {
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

    &:hover {
      transform: none;
      transition: all 100ms ease-in;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    }
  }

  &.highlight {
    .houseCard {
      border: 1px solid $secondary;
      animation: zoomOut 0.5s both;
    }
  }

  &.nearbyCard .InsertCard {
    background: $insertLinkBG;
  }

  &.ownProperty .InsertCard {
    background: $myHomeYellow;
  }

  &.referBg .InsertCard {
    background: $referBg;
  }

  &.freeGuide .InsertCard {
    background: $freeGuideBG;

    .descSection {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }

  &.similarCard .InsertCard {
    .descSection {
      width: 30%;
      padding-right: 20px;

      @include mobile {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  .groupSimilarHouses {
    padding: 5px;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 175px;
    z-index: 1;
    background: $white;
    text-align: center;
    color: $secondary;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    font-size: $smallSize;

    svg {
      padding: 0 5px;
    }
  }

  .imagePlaceholder {
    background-color: $grey4;
    text-align: center;
    height: 135px;
    border-radius: 4px;

    @include mobile {
      height: 100px;
    }

    .carouselImage {
      position: relative;
    }

    .placeHolderIcon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      background-size: cover;
      opacity: 0.8;
    }
  }

  &.groupCard {
    margin-bottom: 20px;

    &.expand {
      margin-bottom: 0;
      padding: 0;

      .groupSimilarHouses {
        bottom: -15px;
      }
    }

    .carouselImage {
      position: relative;
    }

    .navArrow,
    .slider-control-bottomcenter {
      display: none !important;
    }

    .placeHolderIcon {
      display: none;
    }

    .imagePlaceholder {
      background-color: $grey3;
    }
  }

  .houseCartTopSection {
    width: 20%;
    display: inline-block;
    position: relative;

    @include mobile {
      width: 40%;
      vertical-align: top;
    }

    .groupCardContent {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      text-align: center;
      line-height: 20px;
      font-size: 25px;
      margin: auto;
      z-index: 2;

      .houseCount {
        width: 100%;
        font-size: 28px;
        display: inline-block;
      }

      .soldOutGroup {
        font-size: 22px;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      }

      span {
        font-size: 12px;
      }
    }

    .soldOutLabel {
      width: 100%;
      position: absolute;
      top: 50%;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      transform: translateY(-50%);
      color: $white;
      text-align: center;
      line-height: 20px;
      font-size: 22px;
      margin: auto;
      z-index: 3;
    }
  }

  .houseCard {
    padding: 16px;
    display: block;
    background-color: $white;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;

    &.soldOut {
      pointer-events: none;
      background-color: $grey5;
      filter: grayscale(1);
      box-shadow: none;

      .visitInfo {
        height: 36px;
      }

      .savButtonWidget {
        display: none;
      }

      .carouselImage {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: $overlayBg;
        }
      }
    }

    .ellipsisDouble {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin-bottom: 8px;
    }

    &:hover {
      .houseCardImages {
        .navArrow {
          opacity: 0.9;
        }
      }
    }

    .houseCardImages {
      height: 135px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;

      @include mobile {
        height: 100px;
      }

      .carouselImage {
        width: 100%;
        height: 135px;
        background: no-repeat center center;
        background-size: cover;

        @include mobile {
          height: 100px;
        }
      }

      .slider-control-bottomcenter {
        display: block;
        width: 100%;
        margin: auto;
        text-align: center;
        background:
          linear-gradient(
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.3) 100%
          );

        @include mobile {
          display: none;
        }

        ul {
          top: -8px !important; // this is requierd for overwrite nuka inline styles
          li {
            button {
              font-size: 19px !important; // this is requierd for overwrite nuka inline styles
              color: $white !important;
              padding: 4px !important;
            }
          }
        }
      }
    }

    .genderTag {
      display: inline-block;
      background-color: $white;
      border: solid 1px $grey4;
      border-radius: 20px;
      padding: 3px 7px;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 3;

      @include mobile {
        padding: 2px 4px;
        font-size: $errorTextSizeSmall;
        top: 6px;
        left: 6px;
      }
    }

    .primeTag {
      display: inline-block;
      background-color: $tertiary;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      padding: 3px 7px;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3;
    }

    .nestawayPG {
      display: inline-block;
      margin-left: 5px;
      color: $black;
      text-align: center;
    }

    .cardContent {
      width: 60%;
      display: inline-block;
      vertical-align: top;
      padding-left: 20px;
      padding-bottom: 0;
      position: relative;

      @include mobile {
        padding-left: 12px;
      }

      &.soldOut {
        background-color: $grey5;
      }

      .houseTittle {
        font-size: 13px;
        color: $brown;
        padding-bottom: 10px;
      }

      .houseSeoTittle {
        font-size: $regularBoldSize;
        margin-top: 10px;
        min-height: 62px;

        @include mobile {
          margin-top: 5px;
          font-size: $smallSize;
          min-height: auto;
        }
      }

      .houseOtherInfo {
        font-size: 12px;
        color: $grey3;
        padding-bottom: 15px;
        text-transform: capitalize;

        &.amenitySpace {
          padding-top: 18px;
          padding-bottom: 30px;

          @include mobile {
            padding: 0;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.Srp {
  &.mapView {
    .GroupExpandContainer {
      .groupHouseWrap {
        width: 70%;
      }
    }

    @include mobile {
      .MapArea {
        width: 100%;
        top: 0;
        z-index: 8;
      }

      .navArrow,
      .offerBannerWidget {
        display: none;
      }

      .ResultArea {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0;
        z-index: 11;
        white-space: nowrap;
        overflow: auto;
        overflow-x: scroll;
        margin-left: 14px;

        .leftContainer {
          display: none;
        }

        .paginationArea {
          display: none;
        }

        .paginationAreaMweb {
          display: inline-block;
          background-color: $white;
          width: 18%;
          margin: 0 10px;
          height: 97px;
          min-height: auto;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);
          border-radius: 4px;
          text-align: center;
          padding: 40px 0;
          margin-top: 20px;

          span {
            vertical-align: middle;
          }
        }
      }

      .houseCartTopSection {
        display: inline-block;
        width: 40%;
        vertical-align: top;
      }

      $mapViewImageHeight: 95px;

      .imagePlaceholder {
        height: $mapViewImageHeight + 2px;
      }

      .houseCardWrapper {
        width: 85%;
        white-space: normal;
        padding-right: 10px;
        display: inline-block;
        position: relative;
        padding-top: 20px;

        .groupSimilarHouses {
          top: 5px;
          bottom: auto;
        }

        .houseCardImages {
          display: inline-block;
          width: 100%;
          height: $mapViewImageHeight;
          padding: 0;
        }

        .carouselImage {
          height: $mapViewImageHeight;
        }

        .genderTag {
          display: none;
        }

        .cardContent {
          width: 60%;
          display: inline-block;
          vertical-align: top;
          padding: 0;
          padding: 10px;

          .houseTittle {
            display: none;
          }

          .houseOtherInfo {
            display: none;
          }
        }

        .houseCard {
          height: 97px;
          min-height: auto;
          padding: 23px 16px;
        }

        .houseSeoTittle {
          font-size: 12px;
          margin-bottom: 7px;

          &.ellipsisDouble {
            margin-bottom: 0;
          }
        }

        .houseRentInfo {
          padding-top: 0;

          .houseRent {
            width: 100%;
            font-size: 15px;
            border-right: 0;
            text-align: left;

            span {
              font-size: 10px;
            }
          }

          .houseDeposit {
            display: none;
          }
        }

        &.groupCard {
          .houseCard {
            padding-bottom: 15px;
            padding-top: 30px;
          }
        }
      }
    }

    .srpVisitButton {
      width: auto;
    }
  }

  .sortMobIcon {
    .DropDown {
      .activeState {
        width: auto;

        .dropdown-text {
          width: auto;
        }
      }
    }
  }

  &.gridView {
    .MapArea {
      display: none;
    }

    .ResultArea {
      width: 100%;
      max-width: 1100px;
      margin: auto;

      @include mobile {
        padding: 0 12px;
        padding-top: 25px;
      }

      .houseCardWrapper {
        width: 100%;
        position: relative;

        @include mobile {
          width: 100%;
        }

        @include mobile {
          .houseCardImages {
            padding: 0;
          }
        }
      }

      .offerBannerWidget {
        margin-right: 20px;

        &.fullscreenWrapper {
          max-width: none;
        }
      }
    }
  }

  .paginationAreaMweb {
    display: none;
  }

  .srpAmenitySection {
    color: $grey2;

    @include mobile {
      padding-top: 8px;
    }

    ul {
      li {
        display: inline-block;
        vertical-align: middle;
        padding-right: 8px;

        @include mobile {
          font-size: $extraSmallSize;
        }

        &.furnishingType {
          text-transform: capitalize;

          span {
            padding-right: 8px;
          }
        }

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: $srAmenityBg;
          vertical-align: top;
          text-align: center;
        }
      }
    }
  }
}

.managedBy {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: $brown;

  @include mobile {
    padding-top: 8px;
    position: static;
    font-size: $extraSmallSize;
  }

  img {
    width: 16px;
    vertical-align: middle;
  }
}

.savButtonWidget {
  width: 20%;
  display: inline-block;
  padding: 10px 0;
  text-align: right;
  vertical-align: top;
  position: relative;

  .bedsLeftInfo {
    max-width: 86px;
    position: absolute;
    top: -19px;
    right: 0;
    margin: 0 auto;
    padding: 5px;
    background: $brightRed;
    color: $white;
    font-size: 10px;
    line-height: normal;

    @include mobile {
      right: 0;
      top: -14px;
      left: auto;
    }

    &::after {
      position: absolute;
      bottom: -11px;
      right: 0;
      content: "";
      width: 0;
      height: 0;
      border: solid transparent;
      border-color: rgba(52, 70, 82, 0);
      border-top-color: $brightRed;
      border-width: 6px;
    }
  }

  @include mobile {
    width: 100%;
    margin-top: 15px;
    padding-top: 15px;
    text-align: left;
    border-top: solid 1px $grey4;
  }

  .srpVisitButton {
    width: 188px;
    display: inline-block;
    cursor: pointer;
    padding: 10px 15px;
    margin-bottom: 5px;
    text-align: center;
    background: $secondary;
    color: $white;
    font-size: $smallSize;
    border-radius: 2px;

    @include mobile {
      position: absolute;
      right: 0;
      width: auto;
    }
  }

  .visitInfo {
    color: $grey2;
    font-size: $smallSize;

    @include mobile {
      width: 100%;
      padding-right: 125px;
      padding-top: 10px;
      display: inline-block;
      font-size: $extraSmallSize;
    }
  }
}

.groupCard {
  .houseCard {
    @include mobile {
      padding-bottom: 30px;
    }
  }
}

// Slashed and other rent
.houseRentInfo {
  .houseRent {
    display: inline-block;
    font-size: 20px;

    @include mobile {
      font-size: $regularHeader;
    }

    .strikeThrough {
      margin-left: 5px;
    }

    .newPrice {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      display: inline-block;
      padding: 0 4px;
    }

    span {
      font-size: $smallSize;
      color: $grey3;

      @include mobile {
        font-size: $extraSmallSize;
      }
    }
  }

  .houseDeposit {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    text-align: left;
    padding-top: 6px;
    padding-left: 24px;
    position: relative;
    color: $grey2;

    span {
      display: inline-block;
      padding-right: 4px;
      padding-bottom: 3px;
      font-size: 13px;
      color: $grey3;

      @include mobile {
        font-size: $extraSmallSize;
      }

      img {
        display: inline-block;
        vertical-align: top;
        padding-right: 5px;
        padding-top: 2px;

        @include mobile {
          padding-top: 0;
        }
      }
    }

    .zsdTag {
      position: absolute;
      top: -26px;
      left: -76px;
    }
  }
}

@include mobile {
  .mobileHideDeposite {
    .houseDeposit {
      display: none;
    }
  }

  .mobileHideRent {
    .houseRent {
      display: none;
    }

    .houseDeposit {
      width: 100%;
      padding: 0;
    }
  }
}
