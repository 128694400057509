$primary: #000000;
$secondary: #00CA95;
$tertiary: #FFF900;
$brandTeal: #00FFC0;
$brandBlue: #00E5FF;
$brandGreen: #C6FF00;
$laurel: #3C763D;
$brightTeal: rgba(0, 255, 192, 0.03);

$brown: #954728;
$darkSkyBlue: #4FB2AE;
$mediumRed: #FC7982;
$darkGreen: #279582; //green font hover state
$lightGreenBg: #F2F9FA;
$lightYellow: #FFFFF0;
$lightRed: #FFF1F1;
$lightGreen: #DFF0D8;
$brightRed: #E53B3B;

$white: #FFFFFF;
$black: #000000;
$alert: #CC001A;
$lightRed: #FFF0F0;

//Grey shades - dark to light

$grey1: #282828; //For normal body content
$passiveColor: #4A4A4A;
$grey2: #989898; //For greyish tittle
$grey3: #989898; //For light greyish tittle
$grey4: #DDDDDD; // For border
$grey5: #EEEEEE; //For light border, bg
$grey6: #FAFAFA; //For light greyish background
$grey7: #E8E8E8;
$grey8: #E1E1E1;
$grey9: #F5F5F5;
$greyText: #9B9B9B;

$overlayBg: rgba(0, 0, 0, 0.66);
$overlayWhiteBg: rgba(255, 255, 255, 0.66);

$cardBoxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);

$gradStart: #91FFE5;
$gradEnd: #00FFBF;

// This is added for diwali offer

$diwalipurple: #5820DC;
$diwaliYellor: #F9E900;
$ownerYellow: #FFD400;
$ownerAppBg: #EDEEE5;
$ownerTip: #FFF8D5;
$ownerTip2: #F7F7F7;
$ownerBorder: #EAE9E9;
$ownerHouseCardBorder: #F6F6F6;

$iconRed: #F9324C;
$formInputError: #E62500;
$warningBgRed: #ff9999;
$formInputDisabled: #CECECE;
$questionLabel: #BB9C07;

// for social buttons
$facebook: #3B5999;
$facebookHover: #7697DE;
$whatsapp: #25D366;
$whatsappHover: #6FE49B;
$facebookMessanger: #0084FF;
$facebookMessangerHover: #66B2F9;
$assureBg: #FFD400;

// social
$facebook: #3A66B8;
$google: #DF3224;
// transparent

$tspBlack: rgba(0, 0, 0, 0.17);

$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);

// my Home

$myHomeYellow: #F6E702;
$myHomeBlue: #7EB4FE;
$arrowShadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
$flatmateCardShadow: 0 4px 14px 0 #E8ECF1;
$myHomeBorder: #EBEBEB;
$facebook: #3A66B8;
$google: #DF3224;
$blueNoteBg: #F3F8FF;
$blueNoteColor: #2C4F96;
$myHomeOrange: #FF8F00;
$myHomeTeal: #00C74D;

// help
$helpGreen: #A3FFBB;
$questionTag: #F6E702;
$customCardBgColor: #EEF9F7;
$subCatColor: #C5C5C5;

//hdp interest
$interestBg: #EFFCFC;
$interestFontColor: #212121;
$interestBlockBorder: #F2F2F2;
$interestBlockerBg: #FBFBFB;
$interestTagBorder: #EFEFEF;
$interestTagColor: #898989;
$savDateColor: #763234;
$savDateBg: rgba(152, 152, 152, 0.1);
$savReferBg: #FEFCE5;

// refer owner
$referBorderColor: #D3D3D3;
$referOwnerFilledBorder: #E9E9E9;
$highlightGreen: #7ED321;
$srAmenityBg: #7CFFFF; // srAmenityBg

// society
$borderGrey: #CCCCCC;

//Offers
$offerYellow: #FFF99A;

// rolldice
$feedbackGradient: linear-gradient(
  90deg,
  rgba(228, 72, 72, 1) 0%,
  rgba(55, 227, 13, 1) 100%
);

// SRP Revamp
$insertLinkBG: #DAFFB4;
$referBg: #F5FEFF;
$freeGuideBG: #FAF2F1;



// Rent Chakra
$darkBlueLink: #4265ea;
$lightBlueLink: #2ab7ff;